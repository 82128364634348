<template>
  <div  style="background-color: white; height: 100%;">
    <!-- <div ref="map" id="map"></div> -->
    <!-- <MapShow :linePath="center"></MapShow> -->
    <!--物流跟踪-->
    <div style="position: absolute;bottom: 0;">
      <div
        style="background-color: white;border-top-left-radius: 0.5rem; border-top-right-radius: 0.5rem; margin: auto;padding-bottom: 1rem;">
        <div style="font-size: 1.2rem;color: #111111; margin: 0.5rem;padding-top: 1rem;">物流跟踪</div>
        <div style="max-height: 25rem;">
          <van-steps direction="vertical" :active="0">
            <van-step v-for="(item, index) in getlogisticsList()" :key="index">
              <span class="txt">{{ item.message }}</span>
              <span class="time">{{ item.messageDate }}</span>
            </van-step>
          </van-steps>
        </div>
        <div v-if="logisticsList.length > 2 && !showAll"
          style="display: flex; align-items: center; justify-content: center;">
          <div class="bottomButton" @click="isShowAll">
            查看全部
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { lazyAMapApiLoaderInstance } from 'vue-amap'
import MapShow from "../../components/map/MapShow.vue";
// import carUrl from "../../assets/images/shop.png";
export default {
  name: 'Logic',
  data() {
    return {
      // center: [//点标记的坐标
      //   {
      //     lng: 116.205467, lat: 39.907761,
      //     position: [116.205467, 39.907761]
      //   }, {
      //     lng: 116.368904, lat: 39.913423,
      //     position: [116.368904, 39.913423]
      //   }, {
      //     lng: 116.305467, lat: 39.807761,
      //     position: [116.305467, 39.807761]
      //   }
      // ],

      showAll: false,
      logisticsList: [
        {
          message: '1包裹已签收，感谢您使用顺丰快递服务，期待下次继续为您服务！',
          messageDate: '2016-03-10 18:07:15'
        },
        {
          message: '2包裹已签收，感谢您使用顺丰快递服务，期待下次继续为您服务！',
          messageDate: '2016-03-10 18:07:15'
        },
        {
          message: '3包裹已签收，感谢您使用顺丰快递服务，期待下次继续为您服务！',
          messageDate: '2016-03-10 18:07:15'
        },
        {
          message: '4包裹已签收，感谢您使用顺丰快递服务，期待下次继续为您服务！',
          messageDate: '2016-03-10 18:07:15'
        },
        {
          message: '5包裹已签收，感谢您使用顺丰快递服务，期待下次继续为您服务！',
          messageDate: '2016-03-10 18:07:15'
        },
        {
          message: '6包裹已签收，感谢您使用顺丰快递服务，期待下次继续为您服务！',
          messageDate: '2016-03-10 18:07:15'
        },
        {
          message: '5包裹已签收，感谢您使用顺丰快递服务，期待下次继续为您服务！',
          messageDate: '2016-03-10 18:07:15'
        },
        {
          message: '6包裹已签收，感谢您使用顺丰快递服务，期待下次继续为您服务！',
          messageDate: '2016-03-10 18:07:15'
        },
        {
          message: '5包裹已签收，感谢您使用顺丰快递服务，期待下次继续为您服务！',
          messageDate: '2016-03-10 18:07:15'
        },
        {
          message: '6包裹已签收，感谢您使用顺丰快递服务，期待下次继续为您服务！',
          messageDate: '2016-03-10 18:07:15'
        }
      ]
    }
  },
  components: { MapShow },
  methods: {
    getlogisticsList() {
      if (this.showAll) {
        return this.logisticsList
      } else {
        return this.logisticsList.slice(0, 3)
      }
    },
    isShowAll() {
      this.showAll = !this.showAll
      console.log('showall', this.showAll)
    },


  },
  /**
   * 加载时执行
   */
  mounted() {
    // lazyAMapApiLoaderInstance.load().then(() => {
    //   //map-test为div的id
    //   var map = new AMap.Map('map', {
    //     center: new AMap.LngLat(116.397428, 39.90923)// 地图中心点
    //   })
    //   //点标记
    //   this.center.forEach((center) => {
    //     new AMap.Marker({
    //       map: map,//放置点标记的地图
    //       position: [center.position[0], center.position[1]],
    //       title: '点标记'
    //     }).on('click', function () {
    //       alert('点击了标记')
    //     })
    //   })
    // })

  }

}
</script>
<style scoped>
#map {
  height: 600px;
}

.bottomButton {
  border: 1px solid #aea9a9;
  width: 30%;
  border-radius: 1rem;
  text-align: center;
  padding: 0.2rem 0.5rem;
  color: #aea9a9;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.bottomLayout {
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-top: 1px solid #c3b9b9;
}
.txt{
  font-size: 1rem;
}
.time{
  font-size: 1rem;
}
</style>
