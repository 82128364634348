<template>
  <!-- <div id="map-location"></div> -->
  <div class="app">
    <div ref="map" id="map" style="width: 100%; height: 600PX;"></div>
  </div>
</template>
<script>
//引入高德地图ui组件
import { lazyAMapApiLoaderInstance } from "vue-amap";
import carUrl from "../../assets/images/car.png";
var map;
export default {
  data () {
    return {
      navgtrSpeed: 100000, //播放速度
      navgtr: "",
      pathLsit: [{
        address: "点a",
        lnglat: [116.405289, 39.104987]
      }, {
        address: "点b",
        lnglat: [116.964458, 39.254664]
      }, {
        address: "点c",
        lnglat: [116.47836, 39.335964]
      }, {
        address: "点d",
        lnglat: [116.949297, 39.470904]
      }, {
        address: "点e",
        lnglat: [116.380111, 39.549509]
      }, {
        address: "点f",
        lnglat: [116.774185, 39.66996]
      }, {
        address: "点g",
        lnglat: [116.135432, 39.730601]
      }, {
        address: "点h",
        lnglat: [116.46826, 39.829964]
      }, {
        address: "点i",
        lnglat: [116.777529, 39.966798]
      },]
    };
  },
  mounted () {
    this.init()

  },
  created () {

  },
  props: {
    linePath: {
      type: Array,
    },

  },
  //  ,["linePath", "mapType", "status"],
  // watch: {
  //   linePath: function (newVal, oldVal) {
  //     this.polylinePath = []
  //     this.polylinePath = newVal;
  //     if (this.polylinePath.length > 1) {
  //       // this.init();
  //     }
  //   },
  // },
  methods: {
    //初始化地图插件
    init () {
      // 这里是调用初始化地图插件的时候传入的坐标参数


      let self = this;
      //声明一个一个arr用于存储需要渲染的坐标点
      let path = []

      this.pathLsit.forEach(res => {
        //将后台返回的数据变更成高德需要的数据格式 [113.565655 ,30.25524]经度在前纬度在后
        path.push([res.lnglat[0], res.lnglat[1], res.address]);
      });
      lazyAMapApiLoaderInstance.load().then(() => {
        //初始化地图
        map = new AMap.Map("map", {
          resizeEnable: true,//是否监控地图容器尺寸变化，默认值为false
          zoom: 9, //地图显示的缩放级别
          //地图中心点坐标值
          center: new AMap.LngLat(this.pathLsit[4].lnglat[0], this.pathLsit[4].lnglat[1])// 地图中心点
        });
        this.PathSimplifier()
        this.initPathSimplifier2(path)
        this.initPathSimplifier(path)

      });
    },
    //初始化一个点标记position 是定位点的中心
    PathSimplifier () {
      //这个是添加AMapUI的点标注
      //"overlay/SimpleMarker" 简单的标注
      AMapUI.loadUI(["overlay/SimpleMarker"], PathSimplifier => {
        const marker = new PathSimplifier({
          iconLabel: "起",//文字描述
          iconStyle: "red", // //图标主题
          color: "#fffff",
          //...其他Marker选项...，不包括content
          map: map,
          radius: 5,
          position: this.pathLsit[0].lnglat,
          iconStyle: {
            src: "//webapi.amap.com/theme/v1.3/markers/b/mark_b.png",
            style: {
              width: "20px",
              height: "30px",
              color: "#F00"
            }
          }
        });
        const marker1 = new PathSimplifier({
          iconLabel: "终",//文字描述
          iconStyle: "red", // //图标主题
          color: "#fffff",
          //...其他Marker选项...，不包括content
          map: map,
          radius: 5,
          position: this.pathLsit[this.pathLsit.length - 1].lnglat,
          iconStyle: {
            src: "//webapi.amap.com/theme/v1.3/markers/b/mark_b.png",
            style: {
              width: "20px",
              height: "30px",
              color: "#F00"
            }
          }
        });
        const marker2 = new PathSimplifier({
          // iconLabel: "车",//文字描述
          iconStyle: "red", // //图标主题
          color: "#fffff",
          //...其他Marker选项...，不包括content
          map: map,
          radius: 5,
          position: this.pathLsit[4].lnglat,
          iconStyle: {
            src: carUrl,
            style: {
              width: "50px",
              height: "40px",
              color: "#0F0"
            }
          }
        });
      });
    },
    initPathSimplifier (path) {

      //ui/misc/PathSimplifier 轨迹展示组件
      AMapUI.load(["ui/misc/PathSimplifier", "lib/$"], (PathSimplifier, $) => {
        if (!PathSimplifier.supportCanvas) {
          this.$Toast("当前环境不支持 Canvas！")
          return;
        }
        var defaultRenderOptions = {
          //设置颜色
          renderAllPointsIfNumberBelow: 1000, //绘制路线节点，如不需要可设置为-1
          pathTolerance: 0,
          keyPointTolerance: 0,
          pathLineStyle: {
            //设置显示路线的颜色
            lineWidth: 5,
            strokeStyle: "#EE7C6B",
            borderWidth: 1,
            borderStyle: "#eeeeee",
            dirArrowStyle: true
          },
          keyPointStyle: {
            //设置定位点的颜色
            radius: 3,
            fillStyle: "#ffffff",
            lineWidth: 1,
            strokeStyle: "#ff5252"
          },
          pathLineSelectedStyle: {
            //设置鼠标悬停的颜色
            lineWidth: 6,
            strokeStyle: "#f1ff1b",
            borderWidth: 1,
            borderStyle: "#f01515",
            dirArrowStyle: true
          },
          dirArrowStyle: {
            stepSpace: 35,
            strokeStyle: "#ffffff",
            lineWidth: 2
          },
          pathNavigatorStyle: {
            initRotateDegree: 0,
            width: 20,
            height: 40,
            autoRotate: true,
            lineJoin: "round",
            content: PathSimplifier.Render.Canvas.getImageContent(
              carUrl,
              onload,
              onerror
            ),
            fillStyle: "#159ef0",
            strokeStyle: "#1b9deb",
            lineWidth: 1,
            pathLinePassedStyle: {
              //设置轨迹颜色
              lineWidth: 3,
              strokeStyle: "#00CCFF",
              borderWidth: 1,
              borderStyle: "#eeeeee",
              dirArrowStyle: true
            }
          }
        };
        //核心功能
        var pathSimplifierIns = new PathSimplifier({
          zIndex: 50,
          autoSetFitView: false,
          map: map, //所属的地图实例
          //具体什么作用不知道.官方只说是返回路线坐标点
          getPath: function (pathData, pathIndex) {
            return pathData.path;
          },
          //返回鼠标悬停时显示的信息 坐标点的描述
          getHoverTitle: function (pathData, pathIndex, pointIndex) {
            // console.log(pathData)
            if (pointIndex >= 0) {
              //point
              return (
                // pathData.name +
                // "，点：" +
                // pointIndex +
                // "/" +
                // pathData.path[2]
                // pathData.name +
                "经点" +
                pointIndex +
                "：" +
                pathData.path[pointIndex][2]
              );
            }

            // return pathData.name + "，点数量" + pathData.path.length;
          },
          renderOptions: defaultRenderOptions
        });
        // 这里是设置数据 
        pathSimplifierIns.setData([
          {
            name: "路线0",
            path: path.slice(4, path.length)
          }
        ]);

        // //对第一条线路（即索引 0）创建一个巡航器
        // this.navgtr = pathSimplifierIns.createPathNavigator(0, {
        //   loop: false, //循环播放
        //   speed: this.navgtrSpeed //巡航速度，单位千米/小时
        // });
        // this.navgtr.start();
      });

    },
    initPathSimplifier2 (path) {

      //ui/misc/PathSimplifier 轨迹展示组件
      AMapUI.load(["ui/misc/PathSimplifier", "lib/$"], (PathSimplifier, $) => {
        if (!PathSimplifier.supportCanvas) {
          this.$Toast("当前环境不支持 Canvas！")
          return;
        }
        var defaultRenderOptions = {
          //设置颜色
          renderAllPointsIfNumberBelow: 1000, //绘制路线节点，如不需要可设置为-1
          pathTolerance: 0,
          keyPointTolerance: 0,
          pathLineStyle: {
            //设置显示路线的颜色
            lineWidth: 5,
            strokeStyle: "#8B0016",
            borderWidth: 1,
            borderStyle: "#eeeeee",
            dirArrowStyle: true
          },
          keyPointStyle: {
            //设置定位点的颜色
            radius: 3,
            fillStyle: "#ffffff",
            lineWidth: 1,
            strokeStyle: "#ff5252"
          },
          pathLineSelectedStyle: {
            //设置鼠标悬停的颜色
            lineWidth: 6,
            strokeStyle: "#f1ff1b",
            borderWidth: 1,
            borderStyle: "#f01515",
            dirArrowStyle: true
          },
          dirArrowStyle: {
            stepSpace: 35,
            strokeStyle: "#ffffff",
            lineWidth: 2
          },
          pathNavigatorStyle: {
            initRotateDegree: 0,
            width: 20,
            height: 40,
            autoRotate: true,
            lineJoin: "round",
            content: PathSimplifier.Render.Canvas.getImageContent(
              carUrl,
              onload,
              onerror
            ),
            fillStyle: "#159ef0",
            strokeStyle: "#1b9deb",
            lineWidth: 1,
            pathLinePassedStyle: {
              //设置轨迹颜色
              lineWidth: 3,
              strokeStyle: "#00CCFF",
              borderWidth: 1,
              borderStyle: "#eeeeee",
              dirArrowStyle: true
            }
          }
        };
        //核心功能
        var pathSimplifierIns = new PathSimplifier({
          zIndex: 50,
          autoSetFitView: false,
          map: map, //所属的地图实例
          //具体什么作用不知道.官方只说是返回路线坐标点
          getPath: function (pathData, pathIndex) {
            return pathData.path;
          },
          //返回鼠标悬停时显示的信息 坐标点的描述
          getHoverTitle: function (pathData, pathIndex, pointIndex) {
            // console.log(pathData)
            if (pointIndex >= 0) {
              //point
              return (
                // pathData.name +
                // "，点：" +
                // pointIndex +
                // "/" +
                // pathData.path[2]
                // pathData.name +
                "经点" +
                pointIndex +
                "：" +
                pathData.path[pointIndex][2]
              );
            }

            // return pathData.name + "，点数量" + pathData.path.length;
          },
          renderOptions: defaultRenderOptions
        });
        // 这里是设置数据 
        pathSimplifierIns.setData([
          {
            name: "路线0",
            path: path.slice(0, 5)
          }
        ]);
        // //对第一条线路（即索引 0）创建一个巡航器
        // this.navgtr = pathSimplifierIns.createPathNavigator(0, {
        //   loop: false, //循环播放
        //   speed: this.navgtrSpeed //巡航速度，单位千米/小时
        // });
        // this.navgtr.start();
      });

    },
    // //播放的方法
    // play() {
    //   this.navgtr.start();
    // },
    // //暂停的方法
    // suspend() {
    //   this.navgtr.pause()
    // },
    // // 恢复的方法
    // recover() {
    //   this.navgtrSpeed = 100000
    //   this.navgtr.resume();
    // },
    // //倍数的方法
    // add(val) {
    //   if (val) {
    //     this.navgtrSpeed = 100000 * val
    //   }
    //   console.log(this.navgtrSpeed)
    //   this.navgtr.setSpeed(this.navgtrSpeed)
    // }
  }
}
</script>
<style>
#map-location {
  width: 100%;
  height: 500px;
}

.amap-logo {
  display: none;
  opacity: 0 !important;
}

.amap-copyright {
  opacity: 0;
}
</style>
